/*
 * @Author: your name
 * @Date: 2021-08-17 09:01:29
 * @LastEditTime: 2021-09-27 23:24:48
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hl_admin\src\views\member\api\member.js
 */
import { get, post } from "../../../api/requests";

export const getList = data => {
  return get("member/list", data);
};

export const edit = data => {
  return post("member/edit", data);
};

export const deleteMember = data => {
  return post("member/delete", data);
};
