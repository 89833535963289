/*
 * @Author: your name
 * @Date: 2021-09-27 23:27:42
 * @LastEditTime: 2021-09-27 23:28:47
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \photo-admin\src\views\company\api\company.js
 */
import { get, post } from "../../../api/requests";

export const getList = data => {
  return get("company/list", data);
};

export const edit = data => {
  return post("company/edit", data);
};

export const deleteCompany = data => {
  return post("company/delete", data);
};
