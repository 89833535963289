<!--
 * @Author: your name
 * @Date: 2021-08-16 16:15:49
 * @LastEditTime: 2021-10-08 14:09:11
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \hl_admin\src\views\member\views\list.vue
-->
<template>
  <div>
    <page-header title="会员列表"></page-header>
    <div class="container">
      <el-form :model="search"
               class="dtb-search-form"
               inline
               label-width="100px">
        <el-form-item label="公司：">
          <el-select v-model="search.company_id"
                     placeholder="请选择"
                     remote
                     filterable
                     :remote-method="getSearchCompanyList">
            <el-option v-for="item in searchCompanyList"
                       :key="item.id"
                       :label="item.company_name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label='手机号：'>
          <el-input v-model="search.mobile"
                    placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary"
                     @click="getList">查询</el-button>
          <el-button @click="reset">重置</el-button>
        </el-form-item>
      </el-form>
      <el-button type="primary"
                 class="add"
                 @click="onAddUser">添加用户</el-button>
      <el-table :data="list"
                class="dtb-show-table">
        <el-table-column label="昵称"
                         prop="nick_name"></el-table-column>
        <el-table-column label="手机号"
                         prop="mobile"></el-table-column>
        <el-table-column label="关联公司"
                         prop="company_name"></el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="text"
                       @click="onItemClick(scope.row)">编辑</el-button>
            <el-button type="text"
                       @click="onDeleteItem(scope.row)">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="dtb-table-pagination">
        <span>共{{ totalCount }}条记录</span>
        <el-pagination background
                       layout="prev, pager, next"
                       :current-page.sync="search.page_no"
                       :page-size="search.page_size"
                       :total="totalCount"></el-pagination>
      </div>
    </div>

    <el-dialog :title="selItem.id ? '编辑用户' : '新增用户'"
               :visible.sync="isDlg"
               inline>
      <el-form inline>
        <el-form-item label="手机号：">
          <el-input v-model="selItem.mobile"
                    placeholder=""
                    inline></el-input>
        </el-form-item>
        <el-form-item label="公司：">
          <el-select v-model="selItem.company_id"
                     placeholder="请选择"
                     remote
                     filterable
                     :remote-method="getCompanyList">
            <el-option v-for="item in companyList"
                       :key="item.id"
                       :label="item.company_name"
                       :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="flex-center">
        <el-button type="primary"
                   @click="submit">确定</el-button>
        <el-button @click="isDlg = false">取消</el-button>
      </div>
    </el-dialog>

  </div>
</template>

<script>
import PageHeader from '../../common/components/PageHeader.vue'
import * as api from '../api/member'
import * as companyApi from '../../company/api/company'
export default {
  components: { PageHeader },
  data () {
    return {
      search: { company_id: '', mobile: '', page_no: 1, page_size: 10 },
      totalCount: 0,
      list: [],
      isDlg: false,
      searchCompanyList: [],
      selItem: {},
      companyList: []
    }
  },
  mounted () {
    this.getList()
    this.getSearchCompanyList()
    this.getCompanyList()
  },
  watch: {
    'search.page_no' () {
      this.getList()
    }
  },
  methods: {
    getSearchCompanyList (query) {
      companyApi.getList({ name: query, page_no: 0, page_size: 10 }).then(({ data: { code, data } }) => {
        if (code === 200) {
          this.searchCompanyList = data.list
        }
      })
    },
    getCompanyList (query) {
      companyApi.getList({ name: query, page_no: 0, page_size: 10 }).then(({ data: { code, data } }) => {
        if (code === 200) {
          this.companyList = data.list
        }
      })
    },
    getList () {
      const params = { ... this.search }
      params.page_no = params.page_no - 1
      api.getList(params).then(({ data: { code, data } }) => {
        if (code === 200) {
          this.totalCount = data.total_count
          this.list = data.list
        }
      })
    },
    reset () {
      this.search = { nick_name: '', mobile: '', page_no: 1, page_size: 10 }
    },
    onAddUser () {
      this.isDlg = true
      this.selItem = { mobile: '', company_id: '' }
    },
    onItemClick (row) {
      this.isDlg = true
      this.selItem = row
      this.getCompanyList(this.selItem.company_name)
    },
    onDeleteItem (row) {
      this.$confirm('您确定要删除该用户吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.selItem = row
        this.selItem.status = 0
        this.submit()
      }).catch(() => {
      })
    },
    submit () {
      const params = { ...this.selItem }
      api.edit(params).then(({ data: { code } }) => {
        if (code === 200) {
          this.isDlg = false
          this.getList()
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  flex: 1;
  background: white;
  margin: 18px;
  padding: 18px;
  .add {
    margin: 24px;
    margin-top: 0;
    display: block;
  }
}
</style>

<style lang="scss">
.el-dialog {
  &__body {
    border-top: 1px solid #eaeaea;
    background: white;
    padding: 20px;
  }
}
.record-header {
  background: black;
}
</style>