<template>
  <div>
    <div style="height: 1px;background-color: rgba(0,0,0,0.12); width: 100%;"></div>
    <div class="pageHeader">
      <el-breadcrumb class="bread-class" v-if="paths && paths.length > 0">
         <el-breadcrumb-item v-for="(item,index) in paths" :to="item.path" :key="index">{{item.name}}</el-breadcrumb-item>
      </el-breadcrumb>
      <span class="pageTitle">{{title}}</span>
      <span v-if="subtitle" class="pro-name">{{subtitle}}</span>
    </div>
    <div style="height: 1px;background-color: rgba(0,0,0,0.12); width: 100%;"></div>
  </div>
</template>

<script>
export default {
  props: {
    title: null,
    subtitle: { type: String, default: '' },
    paths: { type: Array, default: null }
  },
  data () {
    return {
    }
  },
  methods: {}
}
</script>

<style scoped="scoped">
  .pageHeader{
    padding: 16px 32px;
    background-color: white;
  }
  .pageTitle {
    display: inline-block;
    width: 100%;
    line-height: 28px;
    text-align: left;
    font-weight: 600;
    font-size: 20px;
  }
  .pro-name {
    margin-top: 8px;
    color: #595959;
  }
  .bread-class {
    margin-bottom: 16px;
  }
</style>
